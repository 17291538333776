<template>
    <div class="chart-wrapper">
        <canvas ref="chart"></canvas>
    </div>
    </template>
    
    <script>
    import Chart from 'chart.js';
    
    export default {
        props: {
            chartData: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                chart: null,
            };
        },
        mounted() {
            this.renderChart();
        },
        computed: {
            formattedChartData() {
                return this.formatData(this.chartData);
            },
        },
        methods: {
            renderChart() {
                // Render the chart using Chart.js
                this.chart = new Chart(this.$refs.chart, {
                    type: 'scatter',
                    data: {
                        labels: this.chartData.lineplots.x,
                        datasets: [{
                                label: 'Scatterplot',
                                data: this.formatScatterData(this.chartData),
                                fill: false,
                                backgroundColor: 'rgba(65, 172, 170)',
                                borderColor: 'rgba(65, 172, 170)',
                                borderWidth: 2,
                                pointRadius: 5,
                                pointHoverRadius: 7,
                                type: 'scatter',
                            },
                            // New dataset for the diagonal line
                            {
                                label: 'Diagonal Line',
                                data: [{
                                        x: Math.min(...this.chartData.lineplots.x),
                                        y: Math.min(...this.chartData.lineplots.y)
                                    },
                                    {
                                        x: Math.max(...this.chartData.lineplots.x),
                                        y: Math.max(...this.chartData.lineplots.y)
                                    },
                                ],
                                fill: false,
                                borderColor: 'rgba(0,0,0)', // Adjust color for the diagonal line
                                borderWidth: 2,
                                type: 'line',
                            },
                            // New dataset for the trendline
                            {
                                label: 'Trendline',
                                data: this.chartData.lineplots,
                                fill: false,
                                borderColor: 'rgba(65, 172, 170)', // Change color here for the trendline
                                borderWidth: 2,
                                type: 'line',
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: true,
                            text: 'Profit Per Employee',
                            fontSize: 16,
                            padding: 10
                        },
                        legend: {
                            display: false,
                            position: 'bottom',
                            labels: {
                                fontColor: 'rgba(0, 0, 0, 1)',
                            },
                        },
                        tooltips: {
                            enabled: false, // Set tooltips to be disabled
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Profit Per Employee',
                                    fontSize: 14,
                                },
                                ticks: {
                                    fontColor: 'rgba(0, 0, 0, 1)',
                                    callback: (value) => value.toFixed(1),
                                },
                            }, ],
                            yAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Growth Score',
                                    fontSize: 14,
                                },
                                ticks: {
                                    fontColor: 'rgba(0, 0, 0, 1)',
                                },
                            }, ],
                        },
                    },
                });
            },
    
            formatScatterData(chartData) {
                const scatterData = chartData.scatterplots;
                const trendlineData = chartData.lineplots;
    
                // Merge scatter and trendline data
                const combinedData = scatterData.y.map((value, index) => ({
                    x: scatterData.x[index],
                    y: value,
                    trendline: trendlineData.y[index],
                }));
    
                return combinedData;
            },
        },
        watch: {
            chartData: {
                handler: 'renderChart',
                deep: true,
            },
        },
    };
    </script>
    
    <style scoped>
    .chart-wrapper {
        width: 100%;
        height: 100%;
        min-height: 300px;
    }
    
    canvas {
        background: #F0F2F4;
        border-radius: 10px;
        padding: 10px;
    }
    </style>
    