<template>
    <div id="reviewpreformance" class="review-preformance bg-greyblue pb-85px relative">
        <div class="container"  v-if="loading == true">
            <div class="flex items-center px-25px pt-100px pb-40px bg-greyblue text-black preformance-review__title-wrapper">
                <div class="lg:w-3/12">
                    <h1 class="text-xl">Preformance Reviews</h1>
                </div>
                <div class="lg:w-3/12 lg:ml-auto">
                        <select v-model="selectedData" class="review-preformance__select">
                            <option v-for="data in dataSet" :value="data.value" :selected="1" :key="data.value">{{ data.text }}</option>
                        </select>
                </div>
            </div>
            <div class="flex flex-col lg:flex-row items-center py-25px mt-25px">
                <div class="lg:w-half flex items-center bg-white rounded py-50px px-25px" style="min-height: 150px">
                    <div class="lg:w-3/12">
                        <p v-if="(selectedData == 1)" class="text-large text-teal">{{resultID.sector.score}}</p>
                        <p v-else-if="(selectedData == 2)" class="text-large text-teal">{{resultID.global.score}}</p>
                    </div>
                    <div class="lg:w-4/12 lg:ml-auto">
                        <p class="text-large">Total score</p>
                    </div>
                </div>
                <div class="lg:w-half flex flex-col ml-50px">
                    <div class="flex items-center bg-white py-40px px-25px">
                        <p class="text-small text-teal py-5px">Your total score is above the current industry average of 513. Our statistics show that SME’s with an industry average of 600 or more, see a 15% increase in yearly revenue.</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col lg:flex-row items-center py-25px">
                <div class="lg:w-half flex items-center bg-white rounded py-50px px-25px">
                    <CardSpiderChart :averageData="averageData" :userData="userData" :yLabels="yLabels" />
                </div>
                <div v-if="(selectedData == 1)" class="lg:w-half flex items-center flex-wrap ml-auto pl-40px pt-50px">
                    <div v-for="(section) in resultID.sector.subsection" :key="section.id" class="lg:w-half lg:mr-25px bg-white py-50px px-25px overflow-hidden review-preformance__card">
                        {{section.name}}
                        <p class="text-large mt-50px mb-25px">Score: {{section.score}}</p>
                    </div>
                </div>
                <div v-else-if="(selectedData == 2)" class="g:w-half flex items-center flex-wrap ml-auto pl-40px pt-50px">
                    <div v-for="(section) in resultID.global.subsection" :key="section.id" class="lg:w-half lg:mr-25px bg-white py-50px px-25px overflow-hidden review-preformance__card">
                        {{section.name}}
                        <p class="text-large mt-50px mb-25px">Score: {{section.score}}</p>
                    </div>
                </div>
            </div>
            <span class="page-seperator"></span>
        </div>
    </div>
    </template>
    
    <script>
    import Vue from 'vue';
    import axios from "axios";
    import firebase from "firebase";
    import store from "../../store";
    import CardSpiderChart from "@/views/cards/CardSpiderChart.vue";
    // import PreformanceDropdown from "@/views/dropdowns/PreformanceDropdown.vue";
    
    export default {
    
        data() {
            return {
                multipleResults: '',
                companies: '',
                resultID: [],
                dropdownPopoverShow: false,
                selectedData: '1',
                dataSet: [
                    { value: 1, text: 'Industry Comparison' },
                    { value: 2, text: 'Global Comparison' },
                ],
                userData: '',
                averageData: '',
                averageDataSector: '',
                yLabels: ['2020, 2021, 2022'], // Update to be an Array
                loading: false,
            };
        },
    
        components: {
            CardSpiderChart,
            // PreformanceDropdown,
        },
    
        created() {
    
            firebase.auth().onAuthStateChanged(user => {
                store.dispatch("fetchUser", user);
    
                axios.defaults.headers = {
                    'Content-Type': 'application/json',
                    Authorization: user.za
                }
    
                let endpoints = [
                    'https://pdai-api-7uc2rb5qma-nw.a.run.app/company/get',
                    'https://pdai-api-7uc2rb5qma-nw.a.run.app/tools/results_by_sector/4',
                    'https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/financial'
                ];
    
                Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                    .then(([{
                        data: companies
                    }, {
                        data: multipleResults
                    }, {
                        data: graphData
                    }, ]) => {
                        this.companies = companies;
                        this.multipleResults = multipleResults;
                        this.graphData = graphData;
                        this.testingStuff();
    
                        // user data
                        this.userData = this.companies.advancedDetails;
    
                        // averageData GLOBAL
                        this.averageData = graphData.global.advancedDetails
    
                        // console.log(this.averageData)
                        //  averageData Sector
                        this.averageDataSector = graphData.sector.advancedDetails
                    });
            });
    
        },
    
        async mounted () {
            // this.testingStuff();
        },
    
        methods: {
            testingStuff() {
                let companyInfo = this.companies;
                let results = companyInfo.results;
                let mainResult = results[0];
    
                let result_id = mainResult.id;
    
                firebase.auth().onAuthStateChanged(user => {
                    store.dispatch("fetchUser", user);
    
                    axios.defaults.headers = {
                        'Content-Type': 'application/json',
                        Authorization: user.za
                    }
    
                    let endpoints = [
                        `https://pdai-api-7uc2rb5qma-nw.a.run.app/statistics/results/${result_id}/3`,
                    ];
    
                    Promise.all(endpoints.map((endpoint) => axios.get(endpoint)))
                        .then(([{
                            data: resultID
                        }]) => {
                            this.resultID = resultID;
                            this.loading = true;
                        });
    
                });
    
            },
    
        },
    };
    </script>
    